import {
  Container,
  Heading,
  Stack,
  Text,
  Button,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { ColorModeSwitcher } from '../ColorModeSwitcher';
// import { ColorModeScript } from "@chakra-ui/react"


export default function WelcomePage() {
  return (
    <>
      {/* <ColorModeScript /> */}
      <ColorModeSwitcher justifySelf="flex-end" />
      <Container maxW={'8xl'}>
        <Stack
          textAlign={'center'}
          align={'center'}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 20, md: 28 }}>
          <Heading
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
            lineHeight={'110%'}>
            Welcome to{' '}
            <Text as={'span'} color={'orange.400'}>
              dxsoft.in
            </Text>
          </Heading>
          <Text color={'gray.500'} maxW={'3xl'}>
            This is a demo welcome page.
          </Text>
          <Text color={'gray.500'}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </Text>
          <Stack spacing={6} direction={'row'}>
            <Button
              rounded={'full'}
              px={6}
              colorScheme={'orange'}
              bg={'orange.400'}
              _hover={{ bg: 'orange.500' }}>
              <Link to="/makemoney">Make Money</Link>
            </Button>
            <Button rounded={'full'} px={6}>
              <Link to="/advertisements">View Ads</Link>
            </Button>
          </Stack>
        </Stack>
      </Container>
    </>
  );
}