// import { Button, ChakraProvider, ColorModeProvider } from "@chakra-ui/react"
// import CallToActionWithIllustration from "./Pages/Page1"

// export function App() {
//   return (
//     <ChakraProvider>
//       <ColorModeProvider>
//       <Button>I just consumed some ⚡️Chakra!</Button>
//       <CallToActionWithIllustration />
//       </ColorModeProvider>
//     </ChakraProvider>
//   )
// }

import * as React from "react"
import {
  ChakraProvider,
  Box,
  Grid,
  theme,
} from "@chakra-ui/react"
// import { ColorModeSwitcher } from "./ColorModeSwitcher"
import WelcomePage from "./Pages/Welcome"
import MakeMoneyPage from "./Pages/MakeMoney"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import AdvertisementsPage from "./Pages/Advertisments"
import HomeFeedPage from "./Pages/HomeFeed"

export const App = () => (
  <ChakraProvider theme={theme}>

    <Box textAlign="center" fontSize="xl">
      <Grid minH="10vh">
        {/* <ColorModeSwitcher justifySelf="flex-end" /> */}

        <BrowserRouter>
          <Routes>
            <Route path="/">
              <Route index element={<WelcomePage />} />
              <Route path="makemoney" element={<MakeMoneyPage />} />
              {/* <Route path="advertisements" element={<AdvertisementsPage />} /> */}
              <Route path="advertisements" element={<HomeFeedPage children={<AdvertisementsPage />} />} />
              <Route path="*" element={<WelcomePage />} />
            </Route>
          </Routes>
        </BrowserRouter>


      </Grid>
    </Box>
  </ChakraProvider>
)